import React, { useEffect } from 'react';
import { StyleSheet, css } from 'aphrodite';

import { black } from 'shared/colors';
import SEO from 'components/SEO/SEO';
import { convertToVW, convertToMobileVW } from 'shared/utils';
import { BREAKPOINTS } from 'shared/constants';

interface Props {
  location: {
    pathname: string;
  };
}

const CheckoutPage = ({ location: { pathname } }: Props) => {
  // Handler for messages sent via postMessage from the Caliva Vue codebase
  const handleMessage = (event: MessageEvent<string>): void => {
    if (event.data === 'toMonogramShop') {
      window.top.location.href = '/products';
    } else if (event.data === 'refreshWindow') {
      window.top.location.href = window.top.location.href;
    }
  };
  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      <SEO title="Checkout" pathname={pathname} />
      <iframe
        src={process.env.GATSBY_CHECKOUT_IFRAME_URL}
        id="iframe"
        title="contains the monogram checkout page"
        className={css(styles.container)}
      />
    </>
  );
};

export default CheckoutPage;

const styles = StyleSheet.create({
  container: {
    position: 'static',
    display: 'block',
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: black,
    border: 'none',
    paddingTop: convertToVW(200),
    [BREAKPOINTS.TABLET]: {
      paddingTop: convertToMobileVW(175),
    },
  },
});
